<template>
    <div class="demo">
        <div class="title">失物招领 - 小程序
            <li>后端使用云函数和egg开发的一个失物招领小程序, 并且记录了一点学习的笔记.</li>
            <!-- blog地址：<a href="">有时间的时候补吧</a> -->
            git地址：<a href="https://gitee.com/thinkerwing/applet">gitee</a>
            <br/>
            <div style="margin-top: 10px;"></div>
        </div>
    </div>
</template>

<style scoped>
    .demo {
        display: flex;
        height: 100%;
        width: 100%;
        color: #333;
        text-shadow: none;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 16px;
        text-align: left;
        margin: 10%;
    }
</style>
<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        }
    }
</script>